// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // ~~~~~~~~~~~~~~~~~~PARİS~~~~~~~~~~~~~~~~~~~~~ //
  // apiUrl: "https://api.ditibparis.fr/api/",
  // serverUrl: "https://api.ditibparis.fr/",
  // logoUrl: "assets/img/ParisLogo.svg",
  // title: 'DITIB Paris',
  // description: 'DITIB Paris Diyanet Vakfı',
  
  // ~~~~~~~~~~~~~~~~~~STRASBOURG~~~~~~~~~~~~~~~~~~~~~ //
  apiUrl: "https://api.ditibstrasbourg.fr/api/",
  serverUrl: "https://api.ditibstrasbourg.fr/",
  logoUrl: "assets/img/StrasbourgLogo.svg",
  title: 'DITIB Strasbourg',
  description: 'DITIB Strasbourg Diyanet Vakfı',

  // ~~~~~~~~~~~~~~~~~~NANTES~~~~~~~~~~~~~~~~~~~~~ //
  // apiUrl: "http://api.ditibnantes.fr/api/",
  // serverUrl: "http://api.ditibnantes.fr/",
  // logoUrl: "assets/img/NantesLogo.svg",
  // title: 'DITIB Nantes',
  // description: 'DITIB Nantes Diyanet Vakfı',



  // ~~~~~~~~~~~~~~~~~~LYON~~~~~~~~~~~~~~~~~~~~~ //
  // apiUrl: "https://api.ditiblyon.fr/api/",
  // serverUrl: "https://api.ditiblyon.fr/",
  // logoUrl: "assets/img/LyonLogo.svg",
  // title: 'DITIB Lyon',
  // description: 'DITIB Lyon Diyanet Vakfı',



  // ~~~~~~~~~~~~~~~~~~LOCAL HOST~~~~~~~~~~~~~~~~~~~~~ //
  // apiUrl: "https://localhost:44313/api/",
  // serverUrl: "https://localhost:44313",
  // logoUrl: "assets/img/StrasbourgLogo.svg",
  // title: 'DITIB Strasbourg',
  // description: 'DITIB Strasbourg Diyanet Vakfı',




  // ##################################################################### //
  // ########################### MÜŞAVİRLİK ############################# //
  // ##################################################################### //
  baseApiUrl: "https://api.ditibfrance.fr/api/",
  baseServerUrl: "https://api.ditibfrance.fr/",



};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
